















import { Component, Vue } from 'vue-property-decorator';
import contactIcon from './assets/contact.svg';

@Component({
  data() {
    return {
      contactIcon,
    };
  },
})
export default class HelpContent extends Vue {}
